import {
  ChakraProvider,
  ChakraProviderProps,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { useMemo } from "react";
import { useStore } from "src/store/store";

const styles = {
  global: (props) => ({
    body: {
      color: mode("gray.800", "whiteAlpha.900")(props),
      bg: mode("white", "#161616")(props),
    },
  }),
};

const components = {
  Button: {
    variants: {
      glass: {
        bg: "#4448",
        backdropFilter: "blur(20px)",
        color: "white",
        _hover: {
          bg: "brand.600",
        },
        _active: {
          bg: "brand.700",
        },
      },
      simple: {
        bg: "transparent",
        color: "gray.800",
        _hover: {
          bg: "gray.100",
        },
        _active: {
          bg: "gray.200",
        },
        border: "1px solid",
      },
    },
  },
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "#161616")(props),
      },
    }),
  },
  Modal: {
    // setup light/dark mode component defaults
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "#161616")(props),
      },
    }),
  },
};

const colors = {
  brand: {
    50: "#f5f5f5",
    100: "#BDD9FF",
    200: "#9DBDFF",
    300: "#7DA2FF",
    400: "#5B87FF",
    500: "#336EEA",
    600: "#2B5FCC",
    700: "#234EAD",
    800: "#1B3D8F",
    900: "#133071",
    A100: "#E6F0FF",
    A200: "#B3D4FF",
    A300: "#2791e8",
  },
  gray: {
    50: "#FAFAFA",
    100: "#ECECEC",
    200: "#DADADA",
    300: "#D0D5D9",
    400: "#B0B5BD",
    500: "#808690",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
};

export const MyChakraProvider: React.FC<ChakraProviderProps> = ({
  children,
  colorModeManager,
}) => {
  const {
    configuration: { colorScheme },
  } = useStore();

  const _theme = useMemo(
    () => extendTheme(theme, withDefaultColorScheme({ colorScheme })),
    [colorScheme]
  );

  return (
    <ChakraProvider theme={_theme} colorModeManager={colorModeManager}>
      {children}
    </ChakraProvider>
  );
};

const theme = extendTheme(
  {
    components,
    styles,
    colors,
  },
  { config: { useSystemColorMode: false, initialColorMode: "light" } },
  withDefaultColorScheme({ colorScheme: "brand" })
);

export default theme;
