import _ from "lodash-es";
import { PageContext } from "src/renderer/types";
import type { PartialDeep } from "type-fest";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
export interface Configuration {
  colorScheme: string;
}

export interface StoreState {
  configuration: Configuration;
}

//runs on the server side
//pageContext.zustandInit is transfered to the browser in the initial html response
export const initializeStoreServer = (pageContext: PageContext) => {
  pageContext.zustandInit = {
    configuration: {
      colorScheme: "brand",
    },
  } as Partial<StoreState>;
  initializeStore(pageContext);
};

//runs on the browser side
export const initializeStore = (pageContext: PageContext) => {
  if (pageContext.zustandInit) {
    setState(pageContext.zustandInit);
  }
};

export const useStore = create<StoreState>()(
  immer(
    devtools((set) => ({
      configuration: {
        colorScheme: "brand",
      },
    }))
  )
);
export const setState = (
  newState:
    | PartialDeep<StoreState>
    | ((state: StoreState) => PartialDeep<StoreState>)
) =>
  useStore.setState((state) => {
    if (typeof newState === "function") {
      Object.assign(state, newState(state));
    } else {
      Object.assign(state, _.mergeWith({}, useStore.getState(), newState));
    }
  });
