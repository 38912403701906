import {
  CaptureConsole,
  Offline,
  ExtraErrorData,
  RewriteFrames,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot, hydrateRoot, Root } from "react-dom/client";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactStreaming } from "react-streaming/client";
import { initializeStore } from "src/store/store";
import type { PageContextBuiltInClient } from "vite-plugin-ssr/client/router";
import { PageShell } from "./PageShell";
import type { PageContext } from "./types";

export const clientRouting = true;
export const prefetchStaticAssets = { when: "VIEWPORT" };

const getEnv = () => {
  if (window.location.origin.includes(".staging.")) {
    return "staging";
  }
  if (window.location.origin.includes(".dev.")) {
    return "dev";
  }
  if (window.location.origin.includes(".local.")) {
    return "local";
  }

  return "prod";
};

const env = getEnv();
Sentry.init({
  environment: env,
  dsn: "https://9fb2820165414fa4be9b98b8372bca17@o1335109.ingest.sentry.io/4504208505372672",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: env === "local" ? 1.0 : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new RewriteFrames({
      iteratee(frame) {
        if (
          frame.filename &&
          frame.filename.startsWith(window.location.origin)
        ) {
          frame.filename =
            "app:///" +
            frame.filename.replace(window.location.origin, "client");
        }
        return frame;
      },
    }),
    new BrowserTracing(),
    new CaptureConsole({
      levels: ["error"],
    }),
    new Offline(),
    new ExtraErrorData({
      depth: 10,
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

let root: Root | null = null;
export async function render(
  pageContext: PageContextBuiltInClient & PageContext
) {
  return Sentry.wrap(() => {
    initializeStore(pageContext);
    const { Page, pageProps } = pageContext;
    console.log(pageContext.routeParams);
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          suspense: true,
        },
      },
    });

    const page = (
      <ReactStreaming>
        <PageShell pageContext={pageContext}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageContext.reactQueryState}>
              <Page {...pageProps} />
            </Hydrate>
          </QueryClientProvider>
        </PageShell>
      </ReactStreaming>
    );
    const container = document.getElementById("page-view")!;
    if (container.innerHTML === "" || !pageContext.isHydration) {
      if (!root) {
        root = createRoot(container);
      }
      root.render(page);
      // SSR
    } else {
      root = hydrateRoot(container, page);
    }
  });
}
